.card {
  width: fit-content;
  border-radius: 8px;
  display: flex;
  align-items: center;
  max-width: 500px;
  cursor: pointer;
}

.dot {
  height: 24px;
  width: 24px;
  border-radius: 100%;
  display: inline-block;
  position: relative;
  margin-right: 20px;
  min-width: 24px;
}

.border6 {
  border-width: 6px;
}

.disabledPointer {
  cursor: not-allowed;
}