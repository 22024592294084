.dragOverBlur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(52, 58, 64, 0.10);
  box-shadow: 0px 4px 80px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 997;
  padding: 10px;
}

.dragOverBorder {
  border: 4px dashed #6C757D;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 998;
  width: 99%;
  height: 99%;
}

.dragOverText {
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 10px;
}