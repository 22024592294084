@media screen and (max-width: 767px) {

    h1 {
        font-size:18px !important;
    }

    h2 {
        font-size:16px !important;
    }

    h3 {
        font-size:14px !important;
    }

    h4, h5, h6 {
        font-size:13px !important;
    }

    .profile-screen-wrapper {
        table {
            td {
                font-size:11px !important;

                * {
                    font-size:11px !important;
                    line-height: 1 !important;
                }

                button {
                    font-size:20px !important;

                    svg {
                        font-size:20px !important;

                        path {
                            font-size:20px !important;
                        }
                    }
                }
            }
        }

        margin-bottom: 50px;
    }

    svg {
        path {
            font-size:20px !important;
        }
    }

    
}