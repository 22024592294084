@import "variables.scss";

div {
    &.disabled_row {
        *, a, div, span {
            color:$disabled-text-grey; 
        }
    }
}

.text-border-notice {
  padding: 15px;
  border: 1px solid #FF0000;
  border-radius: 5px;
}