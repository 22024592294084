@import url('https://fonts.googleapis.com/css?family=Roboto');

$disabled-text-grey: #8d8d8d;
$default-orange: #EB7700;
$default-bg-light-grey:#F8F9FA;
$input-dark-grey:#6C757D;

$default-light-font-weight: 200;

$default-border-radius:3px;

$default-input-height:36px;

$default-padding:12px;

$h1-font-size:48px;
$h2-font-size:24px;
$default-font-size:14px;
$select-font-size:14px;

$h1-font-weight:700;
$h2-font-weight:400;
$h2-font-weight-bold:600;
$p-font-weight:400;
$p-font-weight:600;

$h-font-family:"freight-sans-condensed-pro";
$p-font-family:"usual";