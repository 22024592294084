@import "variables.scss";

#layout-wrapper {
  * {
    font-family: $h-font-family;
  }
  &.mobile {
    margin-top:theme('dimensions.heights.layoutWrapperMobileMarginTop');
    .easy-ticket-top-nav-bar  {
      position:fixed;
      z-index:10;
      width:100%;
      left:0px;
      top:0px; 
      height:theme("dimensions.heights.defaultNavbarHeight");

      &.company-page {
        height:theme("dimensions.heights.defaultNavbarHeight");
      }
    }
 
    img {
      &.easy-ticket-top-logo {
        display:block;
        margin:0px auto;
        max-width:70%;
      }
    } 

    #easyticket-footer-2 {
      position:fixed;
      z-index:10;
      bottom:0px;
      left:0px;
      width:100%;
    
      a {
        border:1px solid #333;
        border-radius:100px;
        padding:0px 10px;
        height:25px;
        display:block;
        line-height: 25px;
        width:90%;
        max-width:400px;
        margin:0px auto;
        text-align: center;
        font-weight: bold;
      }
    
    }
  }
}

span {
  &.confirmDataScreenTitle {
    display:block;
  }
  &.confirmDataScreenTitle2 {
    margin:10px 0px;
    display:block;
  }
}

.wallet-items-wrapper {
  overflow-y: auto;
  border-bottom-width: theme("dimensions.heights.defaultFooterHeight");
  border-bottom-style: solid;
  border-bottom-color: transparent;

  .walletItemWrapper {
    align-items: center;
    cursor:pointer;

    .walletItemWrapperLeft {
      width:60%;

      .walletItemPrice {
        color:theme("colors.app.mediumgrey");
      }
    }

    .walletItemWrapperRight {
      width:40%;
      align-content: center;
      display: flex;
      justify-content:space-between;

      svg {
        height:20px;
      }
    }
  }
}

.easyticket-footer {
  .footer-link {
    text-align: center;
    width:33.3%;
    cursor:pointer;
    text-transform:capitalize;
  }
}

#easyticket-footer {
  position:fixed;
  z-index:10;
  bottom:0px;
  left:0px;
  width:100%;
  height:theme("dimensions.heights.defaultFooterHeight");
  border-top:2px solid #eeeeee;

  a {
    &.footer-link {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: nowrap;
      &.active {
        color: theme('colors.app.defaultColor');
        font-weight: 600 !important
      }

      .active-dot {
        display: inline-block;
        width:6px;
        height:6px;
        border-radius: 50%;
        background-color:theme('colors.app.defaultColor');
      }
    }
  }
}

.back-to-mobile-button-wrapper {
  bottom:0px;
  left:0px;
  width:100%;
  border-top:1px solid #ccc;
  z-index:2;
  
  a {
    border:1px solid #333;
    border-radius:100px;
    padding:0px 10px;
    height:25px;
    display:block;
    line-height: 25px;
    width:90%;
    max-width:400px;
    margin:0px auto;
    text-align: center;
    font-weight: bold;
  }
}

.easyticket-qty-choose-wrapper {
  justify-content: space-between;
}

#easyticket-slider-wrapper {
  /*flex-wrap: nowrap;*/
  overflow-x: hidden;
  /*-webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar; */

  > div {
    flex: 0 0 auto; 
    transition: all 1s;
  }
}

.easyticket-customer-select-wrapper,
.easyticket-language-select-wrapper {
  select {
    border:1px solid theme("colors.app.disabledlight");
    height:32px;
    line-height:32px;
    padding:0px 5px;
  }
}

.easticket-logout-button-wrapper {
  border-top:1px solid theme("colors.app.reallyLightGrey");
  padding-top:40px;
  max-width:80%;
  margin:0px auto;
  width:600px;

  a {
    display:block;
    margin:0 auto;
    width:130px;
    text-align:center;
    color:theme("colors.app.defaultColor");
    
  }
}

.easyticket-alert {
  font-size:13px;
  line-height: 1;
}

.circle-check-wrapper {
  border-radius:1000px;
  width:130px;
  height:130px;
  border:8px solid theme('colors.app.checkGreen');
  margin:0px auto;

  &.error {
    border:8px solid theme('colors.app.defaultColor');
  }

  svg {
    width:100px;
    height:100px;
    margin:7px !important;
  }
}

.success-back-button {
  text-transform:capitalize;
}

.account-tab-wrapper {
  padding-bottom:theme('dimensions.heights.defaultFooterHeight');

  .account-default-t-margin {
    margin-top:theme('dimensions.heights.accountDefaultYMargin');
  }

  .account-default-b-margin {
    margin-bottom:theme('dimensions.heights.accountDefaultYMargin');
  }
}