
.cell {
  @apply px-4 h-12;
  border: 1px solid #C2C9D1;
}

.bodyCell {
  max-width: 400px;
  min-width: 250px;
}

.headerText {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.cellInput {
  background-color: inherit;
  width: 100%;
  outline: none;
}

.cellInput:focus {
  outline: unset;
}

.invalidRow {
  background-color: #FFF1F2;
}

.tableHeader {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dayPicker {
  background-color: #ffffff; 
  border: 1px solid #dadce0; 
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  padding: 16px;
  width: 348px;
  max-height: 80vh;
  overflow: hidden;
}

.dayPicker :global(.rdp-table) {
  font-family: 'Roboto', sans-serif;
}

.dayPicker :global(.rdp-day) {
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  font-size: 14px;
  color: #202124;
  border-radius: 50%;
}

.dayPicker :global(.rdp-day:hover) {
  background-color: #f1f3f4;
}

.dayPicker :global(.rdp-day--today) {
  color: #1a73e8;
}

.dayPicker :global(.rdp-day--selected) {
  background-color: #1a73e8;
  color: #ffffff;
}

.dayPicker :global(.rdp-day--selected:hover) {
  background-color: #174ea6;
}

.dayPicker :global(.rdp-day--outside) {
  color: #70757a;
}

.dayPicker :global(.rdp-day_selected) {
  background-color: red !important;
  color: white !important;
}

.dayPicker :global(.rdp-day_disabled) {
  color: #ced4da;
  pointer-events: none; 
  cursor: not-allowed;
}
