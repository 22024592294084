.wizardContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.header {
  padding: 35px 50px;
  height: 10vh;
}

.bodyContainer {
  padding: 0px 20px 10px 50px;
  height: 80vh;
  overflow-y: auto;
}

.footer {
  border-top: 1px solid black;
  display: flex;
  justify-content: space-between;
  padding: 15px 100px;
  height: 90px;
  background-color: white;
  z-index: 100;
  margin-top: auto;
}
