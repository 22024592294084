.Root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.2);
}

.Ripple {
  display: inline-block;
  position: relative;
  width: 180px;
  height: 180px;
}
.Ripple div {
  position: absolute;
  /* border: 4px solid #eb7700; */
  opacity: 1;
  border-radius: 50%;
  animation: Ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.Ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes Ripple {
  0% {
    top: 86px;
    left: 86px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 172px;
    height: 172px;
    opacity: 0;
  }
}
