@keyframes glow {
  0% {
    text-shadow: 0 0 15px #ff8080, 0 0 25px #ff8080;
  }
  100% {
    text-shadow: 0 0 20px #ff8080, 0 0 30px #ff8080;
  }
}
.glow {
  animation: glow 0.7s infinite alternate;
}