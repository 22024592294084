
@import "variables.scss";

.form-generator label {
    @apply text-app-mediumgrey;
}

.form-generator select, .form-generator input {
    height: unset;
    line-height: unset;
}

.form-generator .input-error {
    @apply text-red-600 mb-4;
}

.form-duplicate-container {
    @apply grid;
    grid-template-columns: repeat(3, 1fr); // This will create a 3 column grid

    .form-group {
        @apply mt-4;

        &:nth-child(n+4) { // This will select the 4th and all subsequent .form-group elements
            grid-column: span 3; // This will make them span all 3 columns
        }

        &:nth-child(-n+3) { // This will select the first 3 .form-group elements
            border-right: 1px solid #99999936;
            margin-right: $default-padding * 3;
            padding-right: $default-padding * 3;

            &:nth-child(3) {
                @apply border-r-0;
            }
        }

        &.privacies {
            @apply col-span-3 border-r-0;
        }
    }
}

.form-group-title {
    @apply text-xl font-bold block mb-4;
}

@media (max-width: 1280px) {

    .form-duplicate-container {
        @apply grid pl-4;
        grid-template-columns: repeat(3, 1fr); // This will create a 3 column grid

        .form-group {
            @apply mt-4 border-r-0 col-span-3;

            &:nth-child(n+4) { // This will select the 4th and all subsequent .form-group elements
                grid-column: span 3; // This will make them span all 3 columns
            }

            &:nth-child(-n+3) { // This will select the first 3 .form-group elements
                border-right: 1px solid #999;
                margin-right: $default-padding * 3;
                padding-right: $default-padding * 3;
                @apply border-r-0;
            }

            &.privacies {
                @apply col-span-3 border-r-0;
            }
        }
    }
}

.form-group > div {
    @apply flex flex-col w-full;
    & > label {
        margin-bottom: calc($default-padding / 2);
    }

    & > input, & > select {
        margin-bottom: $default-padding;
    }
}

.form-group input, .form-group select {
    @apply w-full rounded-md border px-4 py-3 transition-colors focus:outline-none disabled:cursor-not-allowed;
}



.form-generator .errored .input-checkboxes-textarea {
    color: red;
}


.form-generator .input-text-container .input-checkboxes-multiple,
.form-generator .input-dropdown-container .input-checkboxes-multiple,
.form-generator .input-checkboxes-container .input-checkboxes-multiple,
.form-generator .input-datepicker-container .input-checkboxes-multiple {
    display: flex;
    flex-direction: column;
}

.form-generator .input-checkboxes-container .input-checkboxes-option.with-textArea {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;
}

.form-generator .input-checkboxes-container  .input-checkboxes-option.agree-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.form-generator .input-checkboxes-container  .input-checkboxes-option.agree-box input {
    width: initial;
    padding-top: initial;
    padding-bottom: initial;
    margin-bottom: 4px;
}

.form-generator .input-checkboxes-container .input-checkboxes-multiple {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    gap: 0.5rem;
}


.form-generator .input-checkboxes-container .input-checkboxes-multiple input[type="checkbox"] {
   display: none;
}

.form-generator .input-checkboxes-container .input-checkboxes-multiple label.input-checkboxes-label {
    padding: 10px 20px; 
    background-color: white;
    border-color: theme('colors.app.defaultColor');
    border-width: 1px;
    color: theme('colors.app.defaultColor'); 
    cursor: pointer;
}

.form-generator .input-checkboxes-container .input-checkboxes-multiple input[type="checkbox"]:checked + label.input-checkboxes-label {
   background-color: theme('colors.app.defaultColor');
   color: white;
}

.form-generator .input-checkboxes-container .input-checkboxes-multiple input.disabled + label.input-checkboxes-label {
    border-color: #edf2f7;
    background-color: #edf2f7;
    color: #a0aec0;
    cursor: not-allowed;
}


.form-generator .input-checkboxes-container .input-checkboxes-subtitle {
    width: 100%;
    height: 7rem;
    overflow-y: scroll;
    border-style: solid;
    padding: 0.5rem;
    border-width: 1px;
    border-color: rgb(209 213 219);
    @apply text-xs;

    ol {
        padding-left: 15px;
        list-style-type: disc;
    }
}

.form-generator .input-checkboxes-container .agree-box input {
    margin-right: 0.5rem;
}


.form-generator .input-text-container .input-checkboxes-multiple>div,
.form-generator .input-dropdown-container .input-checkboxes-multiple>div,
.form-generator .input-checkboxes-container .input-checkboxes-multiple>div,
.form-generator .input-datepicker-container .input-checkboxes-multiple>div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.35rem;
    margin-bottom: 0.85rem
}

.form-generator-buttons {
    @apply flex md:flex-row justify-start items-center mt-8;
}

.form-generator-buttons button {
    @apply px-4 py-2 rounded-md text-white font-semibold p-8 
    justify-end border-transparent text-white bg-app-defaultColor 
    px-2 inline-flex items-center shadow-sm text-sm font-medium 
    p-2 border-opacity-75 mx-0 space-x-1 mr-2 mt-5 hover:border-opacity-60 hover:bg-opacity-90 
    active:outline-none focus:outline-none transition-all;
    background-color: theme('colors.app.defaultColor');
     &:hover {
        opacity: 0.5;
    }
}

button.back-button {
    background-color: white;
    margin-right: 1rem;
    color: theme('colors.app.defaultColor');
    border: 1px solid theme('colors.app.defaultColor');
    &:hover {
        opacity: 0.5;
    }
}

button.reset-button {
    background-color: transparent;
    margin-right: auto;
    color: red;
    border: none;
    text-decoration: underline;
    justify-self: flex-start;
    &:hover {
        opacity: 0.5;
    }
}

.form-generator .input-autocomplete-container {
   margin-bottom: 0.5rem;
    .loader {
        border: 4px solid #f3f3f3; /* Light grey */
        border-top: 4px solid red; /* Red */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 2s linear infinite;
        position: absolute;
        right: 10px;
        top: 10px;
    }
    
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

    .ul-list-autocomplete {
        background-color: white;
        border: 1px solid #ccc;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        list-style: none;
    
        .ul-list-autocomplete-option {
        padding: 10px;
        transition: background-color 0.3s;
    
        &:hover {
            background-color: #f4f4f4;
        }
        }
    }
}

button.submit-button.disabled {
    opacity: 0.5;
}

.realtime-feedback-message {
    display: flex;
    margin-top: 0.5rem;
    @apply text-sm mb-4;

    &.in-progress {
        @apply text-app-yellowDark;
    }

    &.success {
        @apply text-app-greenDark;
    }

    &.error {
        @apply text-red-600;
    }
}