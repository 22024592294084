@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables.scss";

// I removed it because it was causing conflicts with MUI dropdowns/select causing them to scroll instead of locking the dropdown
/* html {
  overflow: hidden;
} */

body {
  overflow: auto;
}

.divSenzaOL {
  ol {
    list-style: inside !important;

    ol {
      list-style: auto !important;
      list-style-position: inside !important;
    }
  }

  ul {
    list-style: inside !important;
  }
}

.standardListsDiv {
  font-size: smaller;
  ol {
    list-style-type: decimal !important;
    margin: unset !important;
    padding-inline-start: 40px !important;
  }

  ul {
    list-style-type: disc !important;
    margin: unset !important;
    padding-inline-start: 40px !important;
  }
}

@media (max-width: 768px) {
  .sidebar {
    /*margin-left: -350px;*/
    position: absolute;
    top: 50px;
    left: 0px;
    z-index: 11;
  }
}

* {
  font-family: $p-font-family;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $h-font-family;
}

h1 {
  font-size: $h1-font-size;
  font-weight: $h1-font-weight;
}

h2 {
  font-size: $h2-font-size;
  font-weight: $h2-font-weight;

  &.h2-font-weight-bold {
    font-weight: $h2-font-weight-bold;
  }
}

.font-extra-light {
  font-weight: $default-light-font-weight;
}

a {
  &.orange-button {
    color: #fff;
    background-color: $default-orange;
    border-radius: $default-border-radius;
    cursor: pointer;
    text-align: center;
    padding: 0px $default-padding;
    display: inline-block;
    height: $default-input-height;
    line-height: $default-input-height;
    font-size: $default-font-size;
  }

  &.default-color-transparent-button {
    color: theme("colors.app.defaultColor");
    background-color: transparent;
    border-radius: $default-border-radius;
    cursor: pointer;
    text-align: left;
    padding: 0px $default-padding;
    display: inline-block;
    height: $default-input-height;
    line-height: $default-input-height;
    font-size: $default-font-size;
    text-decoration: underline;

    &.pl-0 {
      padding-left: 0px;
    }

    &.disabled-btn {
      color: #8d8d8d;
      cursor: not-allowed;
    }
  }

  &.block {
    display: block;
  }
}

.default-padding {
  padding: $default-padding;
}

select {
  font-size: $select-font-size;
  color: $input-dark-grey;

  option {
    font-size: $select-font-size;
    color: $input-dark-grey;
  }

  &.form-select {
    font-size: $select-font-size;
    color: $input-dark-grey;
    height: 47px;
  }
}

.custom-multiselect {

  border:1px solid theme("colors.app.line") !important;

  :focus {
    border:1px solid theme("colors.app.defaultColor") !important;
    outline: none !important;
  }
  :active {
    border:1px solid theme("colors.app.defaultColor") !important;
    outline: none !important;
  }

  input:focus {
    outline: none !important;
  }
  .MuiBox-root {
    border: none !important;
    outline: none !important;
    :focus {
      border: none !important;
      outline: none !important;
    }
    :active {
      border: none !important;
    outline: none !important;
    }
  }
}

.custom-multiselect fieldset {
  border:1px solid transparent !important;
  outline: none !important;
  :focus {
    border:1px solid theme("colors.app.defaultColor") !important;
    outline: none !important;
  }
  :active {
    border:1px solid theme("colors.app.defaultColor") !important;
    outline: none !important;
  }

}

.Mui-selected {
background-color: theme("colors.app.lightbg") !important;
}

.border-md {
  border-radius: $default-border-radius;
}

.invitation-input {
  & > span {
    margin-bottom: $default-padding / 2;
  }

  & > input,
  & > select {
    margin-bottom: $default-padding;
  }
}

div {
  &.grid-column {
    border-right: 1px solid #999;
    margin-right: $default-padding * 3;
    padding-right: $default-padding * 3;
    &.no-border {
      border: none;
    }
  }
}

.DayPicker-Day {
  cursor: not-allowed;
  color: theme("colors.app.lightbg");

  &.DayPicker-Day--disabled {
    color: theme("colors.app.defaultColor");
    cursor: pointer;
  }
}

.redLight {
  color: theme("colors.app.redLight") !important;
}

svg {
  &.combinable-product-trash {
    margin-top: $default-padding * 4;
  }
}

div {
  &.single-date-wrapper {
    margin-top: 60px;
  }
}

.table-paginator-dropdown {
  background: transparent;
}

table {
  &.head-invitation-table {
    tr {
      td {
        font-weight: 100;

        &.column-type-downloaded {
          text-transform: capitalize;

          * {
            text-transform: capitalize;
          }
        }
      }
    }
  }
}

.border-1 {
  border-width: 1px;
}

.border-b-1 {
  border-bottom-width: 1px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.mt-1-pagination {
  margin-top: 1px;
}

.table-actions-wrapper {
  > div {
    float: left;
  }
}

button {
  &.filter-button {
    padding: 0.75rem;
  }
}

.collaborator-button {
  width: 1.5em;
  height: 1.5em;
}

.row-action-button {
  width: 1.3em;
  height: 1.3em;
}

.ass-modal-desc {
  font-size: 12px;
  font-weight: 100;
  margin-top: 5px !important;
}

.max-w-700 {
  max-width: 700px;
}

.max-w-500 {
  max-width: 500px;
}

th {
  &.w-20 {
    width: 20%;
  }
}

td {
  &.w-20 {
    width: 20%;
  }
}

.flex-first-col {
  flex: 1 1 20%;
}

.button-reload {
  display: inline-block;
}

.autocompleteWrapper {
  position: relative;

  input {
    margin-bottom: 0px !important;
  }

  .customerAutocompleteItemsSelect {
    background: #fff;
    padding: 0px;
    position: absolute;
    width: 100%;
    top: 100%;
    left: 0px;
    max-height: 120px;
    overflow-y: auto;
    z-index: 3;

    &.away {
      z-index: -1;
    }

    .customerAutocompleteItemOption {
      border-bottom: 1px solid #ddd;
      padding: 10px 20px;
      height: auto;
      line-height: 1;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }
  }
}

.select-choose-brand {
  max-width: 200px;
}

.excel-buttons-wrapper {
  button {
    width: 200px;
    display: inline-block;
  }
}

.submitted-msg-wrapper {
  z-index: 10;
}

.SN_svg {
  fill: #fff;
}

.text-2xs {
  font-size: 0.55rem;
  line-height: 0.75rem;
}

.video-choice-wrapper {
  &:hover {
    background-color: theme("colors.app.lightbg");
  }

  a {
    &.active {
      color: theme("colors.app.orange");
      text-decoration: underline;
    }
  }
}

.react-toast-notifications__container {
  transform: translateX(-50%) scale(1.5) !important;
}

.input-checkboxes-container {
  margin-top: 30px;
}
.icon-arrow-mui{
    user-select: none;
    width: 1em;
    height: 1em;
    display: inline-block;
    fill: currentcolor;
    flex-shrink: 0;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-size: 1.5rem;
}
