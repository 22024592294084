.wrapper {
  width: 100%;
  height: 120px;
  display: flex;
}

.stepWrapper {
  align-items: center;
  flex: 1;
  position: relative;
}

.dotsWrapper {
  position: relative;
  width: 28px;
  height: 28px;
}

.lightGreyCircle {
  height: 28px;
  width: 28px;
  border-radius: 100%;
  background-color: rgb(248 249 250);
}

.dot {
  height: 28px;
  width: 28px;
  border: 4px solid rgb(248 249 250);
  border-radius: 100%;
  display: inline-block;
  position: relative;
}

.line {
  height: 4px;
  width: 100%;
  background-color: var(--grey-dark, #6C757D);
  position: absolute;
  top: 12px;
  z-index: -1;
  left: calc(50% + 14px);
}

.dotWhite {
  height: 8px;
  width: 8px;
  border-radius: 100%;
  background-color: #FFFFFF;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.completedStep {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}